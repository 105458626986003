import { useParams } from "react-router-dom";
import useApi from "storybook-dashboard/utils/fetching";
import Im from "immutable";

/* 
    Get the Project, Tracker, Reporting Package and Report ID's from the URL.
    Uses SWR to rapidly get parameters from cached API data
*/

export const useFullIds = () => {
  const { _companyId, _projectId, _refId, _commitId } = useParams();

  let { data: project } = useApi(`/api/project/{_projectId}/`, { _projectId });
  let projectId = project?.get("uid");
  let trackerId = project
    ?.get("trackers")
    ?.filter((i) => i.get("name") === "sustainability_tool")
    ?.first()
    ?.get("uid");

  let { data: company } = useApi(`/api/company/{_companyId}/`, { _companyId });
  let companyId = company?.get("uid") || project?.getIn(["company", "uid"]);

  let { data: tracker } = useApi(`/api/tracker/{trackerId}/`, { trackerId });
  let rootRefId = tracker?.getIn(["root_master", "uid"]);

  let __refId = _refId || rootRefId;
  let { data: ref } = useApi(`/api/tracker/{trackerId}/ref/{__refId}/`, { trackerId, __refId });
  let refId = _refId ? ref?.get("uid") : rootRefId;

  let { data: rootRef } = useApi(`/api/tracker/{trackerId}/ref/{rootRefId}/`, { trackerId, rootRefId });
  let cref = refId ? ref : rootRef;

  // Are we on the root
  let crefId = cref?.get("uid");

  let isRootRef = refId == rootRefId;

  let __commitId = _commitId || ref?.getIn(["latest_commit", "uid"]);
  let { data: commit } = useApi(`/api/tracker/{trackerId}/commit/{__commitId}/`, { trackerId, __commitId });
  let commitId = commit?.get("uid");

  return { companyId, projectId, trackerId, crefId, refId, commitId, isRootRef, company, project, tracker, commit };
};
