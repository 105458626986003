import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Traec from "traec";
import { getPathChildren } from "traec/utils/nodes";
import { BSBtn, BSCard, BSModal } from "traec-react/utils/bootstrap";

import { ErrorBoundary } from "traec-react/errors";
import ReportRowErrorBoundary from "./error";
import { loading } from "traec-react/utils/entities";
import { Spinner } from "traec-react/utils/entities";
import { getProjectProps, loadConvFacts } from "AppSrc/project/utils";
import ReportCategoryItem from "./reportCategoryItem";

import { alertSuccess, confirmProceed } from "traec-react/utils/sweetalert";
import { getCommitStatus } from "AppSrc/project/commits/row";
import { reportingPeriodText } from "AppSrc/project/report/utils";
import { isAllRequiredSubmitted, Submit } from "AppSrc/project/report/submit";
import {
  getChildMetricScores,
  getScoreValuesByBaseMetric,
  hasDiff,
  pathHasInputs,
  reportingPeriodYear,
  setInitialConversions,
} from "./utils";
import Octicon from "react-octicon";
import { Tooltip } from "react-tippy";

import { SetMetaDataFields } from "AppSrc/forms/meta";
import { Redirect } from "react-router";
import { fetchS3Object, DataTable, textToDataArray } from "./reportDocumentForm";
import TipsSpinner from "../../utils/spinners/tipsSpinner";
import WizardModal from "traec-react/utils/bootstrap/wizardModal";
import Im from "immutable"

//const LOG_DOCUMENT_ID = "0051a087-c027-49e0-8889-6354ad6d3743";
const LOG_DOCUMENT_ID = "b439f0b1-d964-4a16-a538-16b0c413ae71";
const LOG_MODAL_ID = `${LOG_DOCUMENT_ID}-global-form`;

/* ###### ACTIONS ###### */

export const confirmProceedText =
  "" +
  "Please confirm you give permission for your data to be included in the industry benchmark. In the survey, you will be asked to identify clients, contractors, and memberships organisations you work with. By selecting these organisations, you give consent to share your aggregated data to them.\n" +
  "We appreciate the sensitive nature of the information and confirm no individual employee will be identifiable as the data will be presented as aggregates.\n" +
  "All data is securely hosted by Amazon Web Services and physically located in London, United Kingdom";

const successText = "Thank you. Your survey was successfully submitted";
const postCommit = (e, props) => {
  e.preventDefault();
  let { projectId, projectReportingPeriods, trackerId, refId, commitId, project } = props;

  /*let postSubmitUrl = refId
    ? `/project/${projectId.substring(0, 8)}/wpack/${refId.substring(0, 8)}/evals`
    : `/project/${projectId.substring(0, 8)}/`;*/
  let companyId = project.getInPath("company.uid")?.substring(0, 8);
  let postSubmitUrl = `/project/${projectId?.substring(0, 8)}/wpack/${refId?.substring(0, 8)}`;

  let fetch = new Traec.Fetch("tracker_ref_commit", "post", { trackerId, refId, commitId });
  fetch.updateFetchParams({
    body: { comment: "Diversity report submission" },
    postSuccessHook: (data) => {
      let rpId = data.reporting_period;
      let rp = projectReportingPeriods ? projectReportingPeriods.get(rpId) : null;
      let rpStr = reportingPeriodText(rp);
      alertSuccess({
        text: successText,
        onConfirm: () => {
          location.href = postSubmitUrl;
        },
      });
    },
  });

  confirmProceed({
    title: "Protecting your data",
    text: confirmProceedText,
    onConfirm: () => {
      fetch.dispatch();
    },
  });
};

const patchCommit = (e, props) => {
  let { trackerId, refId, commitId, projectId } = props;

  let postSubmitUrl = refId
    ? `/project/${projectId.substring(0, 8)}/wpack/${refId.substring(0, 8)}/evals`
    : `/project/${projectId.substring(0, 8)}/`;

  let fetch = new Traec.Fetch("tracker_ref_commit", "patch", { trackerId, refId, commitId });
  fetch.updateFetchParams({
    preFetchHook: (body) => {
      return { ...body, status: "UPDATE" };
    },
    postSuccessHook: (data) => {
      alertSuccess({
        text: successText,
        onConfirm: () => {
          location.href = postSubmitUrl;
        },
      });
    },
  });

  confirmProceed({
    title: "Protecting your data",
    text: confirmProceedText,
    onConfirm: () => {
      fetch.dispatch();
    },
  });
};

const commitDispatchHandler = (dispatch, action, props) => {
  let { commit, projectReportingPeriods } = props;

  // Append the reporting period to the body of the request
  Object.assign(action.fetchParams.body, {
    reportingPeriod: commit.get("reporting_period"),
    recurse: false,
  });

  // Check that we have a valid reporting period
  let rpId = action.fetchParams.body.reportingPeriod;
  if (!rpId && action.fetchParams.method === "POST") {
    alertSuccess({
      title: "Specify a Reporting Period",
      text: `You must provide a valid Reporting Period in order to submit.`,
      iconType: "error",
    });
    return null;
  }

  let rp = projectReportingPeriods ? projectReportingPeriods.get(rpId) : null;
  let rpStr = reportingPeriodText(rp);
  let methodType = action.fetchParams.method === "POST" ? "submitting" : "updating";
  confirmProceed({
    title: "Protecting your data",
    text: confirmProceedText,
    onConfirm: () => {
      dispatch(action);
    },
  });
};

const saveAllInputs = (e, props) => {
  // DEPRECATED - this previously saved all values saved in Redux to the Server
  e.preventDefault();
  let { trackerId, commitId, reportScores } = props;
  let itemsToSave = reportScores
    .mapEntries(([scoreId, i]) => {
      return [
        scoreId,
        {
          metric: scoreId,
          value: i.get("value"),
          comment: i.get("comment"),
          meta_json: {
            noReport: i.get("noReport"),
            conversions: i.get("childConversiosn"),
          },
        },
      ];
    })
    .toList()
    .toJS();
  let fetch = new Traec.Fetch("tracker_commit_value", "post", { trackerId, commitId });
  fetch.updateFetchParams({
    preFetchHook: (body) => {
      return itemsToSave;
    },
  });
  fetch.dispatch();
};

/* ###### COMPONENTS ###### */

const STATUS_PROPS = {
  notstarted: { name: "alert", className: "text-danger" },
  incomplete: { name: "alert", className: "text-warning" },
  complete: { name: "check", className: "text-success" },
};

export const ReportPeriodYear = ({ reportingPeriod }) => {
  return reportingPeriod ? reportingPeriodYear(reportingPeriod) : "";
};

function CategoryTabLabel({ name, _id, show, isSectionComplete, disableInputs, hasInputs }) {
  // Get the properties of the icon that we will use
  let section_status = "complete";
  let tooltipText = "You marked this section complete";
  if (isSectionComplete) {
    section_status = "complete";
    tooltipText = "Marked complete";
  } else {
    if (hasInputs) {
      section_status = "incomplete";
      tooltipText = "In progress";
    } else {
      section_status = "notstarted";
      tooltipText = "Not started";
    }
  }
  let status = STATUS_PROPS[section_status] || {};
  let iconName = status.name || "alert";
  let iconClass = status.className || "text-warning";

  return (
    <a className={`nav-item nav-link ${show ? "active" : ""}`} data-toggle="tab" href={`#${_id}`}>
      <Tooltip
        animateFill={false}
        html={
          <div className="text-left">
            <p>{tooltipText}</p>
          </div>
        }
      >
        {name} <Octicon name={iconName} className={iconClass} />
      </Tooltip>
    </a>
  );
}

function NavButton({ tabDetails, index, step, label }) {
  let toDetails = index + step >= 0 ? tabDetails.get(index + step) : null;
  //console.log("RENDERING NAV BUTTON", index, label, step, toDetails, tabDetails.toJS())
  if (!toDetails) {
    return null;
  }
  return (
    <button
      className="btn btn-sm btn-light shadow rounded-pill m-2"
      style={{ border: "0.5px solid gray" }}
      onClick={(e) => {
        // Use jQuery to emulate pressing next link in the tab
        let current = jQuery(".nav-tabs > .active");
        let next = step > 0 ? current.next("a") : current.prev("a");
        next.trigger("click");
      }}
    >
      {step > 0 ? (
        <b>
          {label} <Octicon name={"chevron-right"} />
        </b>
      ) : (
        <b>
          <Octicon name={"chevron-left"} /> {label}
        </b>
      )}
    </button>
  );
}

function NavButtons(props) {
  return (
    <ErrorBoundary>
      <div className="float-right m-0">
        <NavButton {...props} step={-1} label=" Previous" />
        <NavButton {...props} step={1} label={`Next `} />
      </div>
      <div style={{ clear: "both" }} />
    </ErrorBoundary>
  );
}

function CheckCategoryComplete(props) {
  let { setSectionComplete, categoryPath, isSectionComplete, disableInputs } = props;
  return (
    <ErrorBoundary>
      <div
        className="form-check float-right m-2 shadow rounded-pill d-flex justify-content-center bg-light"
        style={{ border: "0.5px solid gray" }}
      >
        <input
          type="checkbox"
          className="mr-1 pl-0"
          aria-describedby="inputGroup-sizing-lg"
          id="categoryComplete"
          style={{ cursor: "pointer" }}
          disabled={disableInputs}
          checked={isSectionComplete}
          onChange={(e) => setSectionComplete(categoryPath, !isSectionComplete)}
        />
        <label className="form-check-label pr-3 py-1" htmlFor="categoryComplete" style={{ cursor: "pointer" }}>
          <b>Mark complete</b>
        </label>
      </div>
      <div style={{ clear: "both" }} />
    </ErrorBoundary>
  );
}

function CategoryTabContent(props) {
  let { _id, index, tabDetails, show, history } = props;

  console.log("propsInCat", props);

  return (
    <div id={_id} className={`tab-pane fade ${show ? "show active" : ""}`}>
      <CategoryTable {...props} asFullTable={true} hideTitleRow={true} />
      <CheckCategoryComplete {...props} />
      <NavButtons tabDetails={tabDetails} index={index} />
    </div>
  );
}

function TabbedReport(props) {
  let { categoryTrees, sectionsComplete, disableInputs, inputsByCategoryPath, sortKey } = props;

  if (!categoryTrees || categoryTrees.length === 0) {
    return null;
  }

  let tabDetails = categoryTrees
    .filter((i) => i)
    .sortBy((i) => i.get(sortKey || "name"))
    .map((tree, i) => {
      let name = tree.get("name");
      let categoryPath = tree.get("_path");
      let _id = `path_${categoryPath}`;
      let isSectionComplete = sectionsComplete.get(categoryPath) === true;
      let hasInputs = pathHasInputs(categoryPath, inputsByCategoryPath);
      return { name, _id, tree, categoryPath, isSectionComplete, hasInputs };
    });

  let tabLabels = tabDetails.map((details, i) => (
    <CategoryTabLabel key={i} {...details} disableInputs={disableInputs} show={i == 0} />
  ));

  let tabContents = tabDetails.map((details, i) => (
    <CategoryTabContent key={i} index={i} {...props} {...details} tabDetails={tabDetails} show={i == 0} />
  ));

  return (
    <ErrorBoundary>
      <div className="nav nav-tabs" role="tablist">
        {tabLabels}
      </div>

      <ErrorBoundary>
        <div className="tab-content">{tabContents}</div>
      </ErrorBoundary>
    </ErrorBoundary>
  );
}

const requireLogs = (commit) => {
  let nEmployees = parseFloat(commit.getInPath("meta_json.Coverage of data"));
  let required = isNaN(nEmployees) || nEmployees > 250;
  return required;
};

function VerticalTabbedReport(props) {
  let { commit, categoryTrees, sectionsComplete, disableInputs, inputsByCategoryPath, sortKey, disableButton } = props;

  let modalId = "diversityReportModal";

  if (!categoryTrees || categoryTrees.length === 0) {
    return null;
  }

  let tabDetails = categoryTrees
    .filter((i) => i)
    .sortBy((i) => i.get(sortKey || "name"))
    .map((tree, i) => {
      let name = tree.get("name");
      let categoryPath = tree.get("_path");
      let _id = `path_${categoryPath}`;
      let isSectionComplete = sectionsComplete.get(categoryPath) === true;
      let hasInputs = pathHasInputs(categoryPath, inputsByCategoryPath);
      return { name, _id, tree, categoryPath, isSectionComplete, hasInputs };
    });

  let metaComplete = !tabDetails.map((i) => i.isSectionComplete).includes(false);

  let tabLabels = tabDetails.map((details, i) => (
    <CategoryTabLabel key={i} {...details} disableInputs={disableInputs} show={i == 0} />
  ));

  let tabContents = tabDetails.map((details, i) => (
    <CategoryTabContent key={i} index={i} {...props} {...details} tabDetails={tabDetails} show={i == 0} />
  ));

  return (
    <ErrorBoundary>
      <WizardModal
        hideClose={false}
        id={modalId}
        fullWidth={true}
        body={
          <div className="container-lg shadow rounded m-3">
            <ErrorBoundary>
              <div className="nav nav-tabs nav-pills" role="tablist">
                {tabLabels}
              </div>
              <ErrorBoundary>
                <div className="tab-content">{tabContents}</div>
              </ErrorBoundary>
              <BSBtn
                extra_className="m-2"
                text="Save"
                onClick={() => {
                  $(`#${modalId}`).modal("hide");
                }}
              />
            </ErrorBoundary>
            <br />
            <br />
            <br />
          </div>
        }
      />
      <div className="container shadow rounded my-3">
        <div className="row p-2 m-2 justify-content-between">
          <div className="row">
            <h5 className="h5">Step 4/4</h5>
            <span className={`text-muted align-text-bottom mt-1 ml-2 `}>
              <span className="mr-2">
                <Tooltip html={metaComplete ? "Complete" : "Not complete"} animateFill={false}>
                  <Octicon
                    name={metaComplete ? "verified" : "unverified"}
                    className={`${metaComplete ? "text-success" : "text-warning"}`}
                  />
                </Tooltip>
              </span>
              required
            </span>
          </div>
          <BSBtn
            disabled={disableButton}
            text={"Diversity survey"}
            onClick={() => $(`#${modalId}`).modal({ backdrop: "static", keyboard: false })}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
}

function CategoryTableWrapper(props) {
  let { asFullTable, tree } = props;
  // If not rendering a full table then just render children directly
  if (!asFullTable) {
    return props.children;
  }
  // Wrap the children (<tr> elements) in a table
  return (
    <ErrorBoundary>
      <CategoryHelpText tree={tree} />
      <table className="table table-sm">
        <ReportTableHeader />
        <tbody>{props.children}</tbody>
      </table>
    </ErrorBoundary>
  );
}

function CategoryHelpText({ tree }) {
  let helpText = tree?.getInPath("meta_json.helpText");
  if (!helpText) {
    return null;
  }
  return (
    <div className="alert alert-primary my-2" role="alert">
      {helpText}
      <div className="row d-flex justify-content-center">
        <img
          className="m-2 rounded"
          src="https://github.com/ActionSustainability/static-content/blob/master/diversityTool/add-comment-report.gif?raw=true"
        />
        <img
          className="m-2 rounded"
          src="https://github.com/ActionSustainability/static-content/blob/master/diversityTool/mark-complete-checkbox.gif?raw=true"
        />
      </div>
    </div>
  );
}

function CategoryTable(props) {
  let { tree, inputErrors, disableInputs, updateError, asFullTable, hideTitleRow } = props;

  // Pass through only validation errors for this issue/category
  let path = tree.get("_path");
  let _inputErrors = (inputErrors || Traec.Im.Map()).get(path);

  // Clean up the props to avoid passing unneccessary stuff that will cause a category to update (for performance)
  let _props = { ...props };
  let keysToRemove = [
    "tabDetails",
    "sectionsComplete",
    "inputErrors",
    "reportScores",
    "scoreValues",
    "inputsByCategoryPath",
    "metricScores",
  ];
  for (let key of keysToRemove) {
    delete _props[key];
  }

  return (
    <CategoryTableWrapper asFullTable={asFullTable} tree={tree}>
      <ReportCategoryItem
        {..._props}
        tree={tree}
        path={path}
        categoryPath={path} // Keep a record of the category/issue path (for section complete and validation)
        inputErrors={_inputErrors}
        hideTitleRow={hideTitleRow}
        disableInputs={disableInputs}
        updateInputErrors={updateError}
      />
    </CategoryTableWrapper>
  );
}

function CategoryTableRows(props) {
  let { categoryTrees, sortKey } = props;

  if (!categoryTrees || categoryTrees.length === 0) {
    return null;
  }

  return categoryTrees
    .filter((i) => i)
    .sortBy((i) => i.get(sortKey || "name"))
    .map((tree, i) => (
      <ReportRowErrorBoundary key={i} msg={<td colSpan="100%">Error loading issue section: {tree.get("name")}</td>}>
        <CategoryTable {...props} tree={tree} hideTitleRow={false} asFullTable={false} />
      </ReportRowErrorBoundary>
    ));
}

function ReportTableHeader(props) {
  const titles = [
    { name: "Metric", width: "50%", className: "text-left" },
    { name: "Units", width: "5%", className: "text-left" },
    { name: "Value", width: "10%", className: "text-left" },
    { name: "Comments", width: "20%", className: "text-left" },
    { name: "N/A", width: "5%" },
    { name: "", width: "5%", className: "text-nowrap" },
  ];
  const headCols = titles.map((title, i) => (
    <th
      key={i}
      width={title.width}
      className={`${title.className} border-top-0` || "text-center border-top-0"}
      scope="col"
    >
      {title.name}
    </th>
  ));
  return (
    <thead>
      <tr>{headCols}</tr>
    </thead>
  );
}

function TableReport(props) {
  let { commit, isLoading } = props;
  if (isLoading || !commit) {
    return null;
  }

  return (
    <table className="table table-sm table-hover">
      <ReportTableHeader />
      <tbody>
        <CategoryTableRows {...props} />
      </tbody>
    </table>
  );
}

function ReportHeadline({ commit, disableInputs }) {
  let headline = null;
  if (!disableInputs) {
    //headline = "Report Due";
  } else if (disableInputs && !getCommitStatus(commit)) {
    //headline = "Report Due";
  } else {
    headline = "Report Submitted";
  }
  return headline;
}

function ReportCommitForm(props) {
  let {
    projectId,
    trackerId,
    refId,
    commitId,
    commit,
    isLoading,
    inputErrors,
    needsRevision,
    projectReportingPeriods,
    revalidate,
  } = props;

  if (isLoading) {
    return null;
  }

  // Get the fetch that will be used
  let method = needsRevision() ? "patch" : "post";
  let fetch = new Traec.Fetch("tracker_ref_commit", method, { trackerId, refId, commitId });
  let postSubmitUrl = refId
    ? `/project/${projectId.substring(0, 8)}/wpack/${refId.substring(0, 8)}/evals`
    : `/project/${projectId.substring(0, 8)}/`;
  fetch.updateFetchParams({
    preFetchHook: (body) => (method == "patch" ? { ...body, status: "UPDATE" } : body),
    postSuccessHook: (data) => {
      let rpId = data.reporting_period;
      let rp = projectReportingPeriods ? projectReportingPeriods.get(rpId) : null;
      let rpStr = rp ? ` for ${reportingPeriodText(rp)}` : null;
      let methodStr = method == "post" ? "submitted" : "updated";
      alertSuccess({
        //text: `Thank you for submitting your data${rpStr}.  Your report was successfully ${methodStr}.`,
        text: `Thank you for submitting your 2022 data. Your dashboard may take a few minutes to load, if not please press refresh or come back in 30 minutes.  The industry benchmarks and ONS targets will appear on your company Dashboard in October. You will also be able to access the Industry Dashboard at this time.`,
        onConfirm: () => {
          location.href = postSubmitUrl;
        },
      });
    },
  });

  let placeholder_text = (commit || Traec.Im.Map()).getInPath("meta_json.submit_placeholder") || "Write a comment...";

  return (
    <ErrorBoundary title="Error loading submission button">
      {/* <div className="container shadow rounded my-3"> */}
      <div className="row p-2 my-4 justify-content-center">
        <Submit
          {...props}
          postCommit={(e) => postCommit(e, props)}
          patchCommit={(e) => patchCommit(e, props)}
          revalidate={revalidate}
          needsRevision={needsRevision}
          inputErrors={inputErrors}
          buttonText="Submit Survey"
        />
      </div>
      {/* </div> */}
      <div style={{ clear: "both" }} className="mb-2" />
      {/*
      <BaseFormConnected
        dispatchHandler={(dispatch, action) => commitDispatchHandler(dispatch, action, props)}
        params={fetch.params}
        fields={{
          comment: { label: "", value: "", class: "col-sm-12", placeholder: placeholder_text, endRow: true }
        }}
      />
      <div style={{ clear: "both" }} />
      */}
    </ErrorBoundary>
  );
}

const REPORT_TYPES = {
  classic: TableReport,
  tabbed: VerticalTabbedReport,
};

function ReportBody(props) {
  const { commit, project, tracker, convFactorDetails, scoreValues, commitNodes, hasConversionMap } = props;

  // Ensure we have everything we need to render the report
  //console.log("AAAAAAAAAAAAA", commit, hasConversionMap, convFactorDetails);
  if (
    !(
      commit &&
      project &&
      tracker &&
      convFactorDetails &&
      convFactorDetails.convFactorMap &&
      //scoreValues &&
      commitNodes &&
      hasConversionMap
    )
  ) {
    return null;
  }

  // Get the format of the report to render (tabbed or classic table)
  let report_layout = commit.getInPath("meta_json.report_layout") || "classic";
  let ReportComponent = REPORT_TYPES[report_layout] || TableReport;

  return (
    <ErrorBoundary title="Error loading report">
      <div className="d-flex flex-column">
        {/*<ul>
        <li>
          If you are completing a full report then you may upload data for sections 01 to 07 using the Excel CSV option
          below in section 01. <b>The remaining sections, 08 to 12, will still require manual input.</b>
        </li>
        <li>Company information may be edited at any time by clicking on the company information button.</li>
        <li>You may leave a comment against all input values in the form using the comment box provided.</li>
        <li>
          Organisations with 500 employees or more are expected to upload employee data via CSV in section 01. For
          smaller organisations, you have the option to upload employee data via CSV in section 01 or manually complete
          all the sections in this survey.
        </li>
        </ul>*/}
        {/* Set defined meta-data input fields for the form */}
        <ReportMetaModal {...props} modalTitle={"Company information"} btnText="Company information" />
        {/* Render the table for all inputs */}
        {/*<ReportComponent {...props} />*/}
      </div>
      {/* Render the submit for approval panel */}
      <ReportCommitForm {...props} />
    </ErrorBoundary>
  );
}

export const isMetaComplete = (meta) => {
  if (!meta) {
    return false;
  }
  let input_details = meta?.get("input_details") || Traec.Im.Map();
  let fields = input_details.get("fields") || Traec.Im.List();
  console.log("Checking if report meta complete", fields?.toJS(), meta?.toJS());
  return fields.every((i) => meta?.get(i?.get("header")));
};

const loadEmployeeLogForm = (props, clickAway = false) => {
  let { log_doc, log_doc_status, trackerId, commitId, setData } = props;
  let currentDocObject = log_doc_status?.get("current_object");

  // Allow  the user to click away if there is already some data provided (they are editing)
  //if (currentDocObject) { clickAway = true }

  let modal_opts = clickAway ? "show" : { backdrop: "static", keyboard: false };
  //let _boolId = "08c52c0b-fae9-442a-bd8c-3edfd6af4694-on";
  //let modalId = LOG_MODAL_ID;
  let modalId = `${log_doc?.get("uid")}-global-modal`;

  // Open the modal no matter what (to avoid delay in the UI)
  $(`#${modalId}`).modal(modal_opts);

  // Try to fetch the data asynchronously
  fetchS3Object({
    trackerId,
    commitId,
    docId: log_doc.get("uid"), //LOG_DOCUMENT_ID,
    currentDocObject,
    responseHandler: (response) => {
      if (!response.ok) {
        console.warn(`Response not ok (got status ${response.status})`);
        return;
      }
      return response
        .text()
        .then((_text) => {
          setData(Traec.Im.fromJS(textToDataArray(_text)));
        })
        .catch((e) => {
          console.warn("ERROR LOADING DATA");
        });
    },
    failureHandler: (error) => {
      console.log("Error fetching data", modalId, error);
    },
  });
};

const handleMetaComplete = (_meta, modalId, props) => {
  console.log("Submitted meta-data form is complete.  Closing modal");
  $(`#${modalId}`).modal("hide");

  let nEmployees = _meta.get("Coverage of data") || 0;
  let _text =
    nEmployees < 250
      ? "Your company information is saved.  You may complete the report."
      : "Your company information is saved. As you have reported more than 250 employees you are required to enter data via the Current employee log and Voluntary leavers log in steps 2 and 3.";
  //let _onConfirm = () => loadEmployeeLogForm(props, nEmployees < 250);

  alertSuccess({
    title: "Company information complete",
    text: _text,
    iconType: "success",
    //onConfirm: _onConfirm,
    //onCancel: _onConfirm
  });
};

function EmployeeLogModal(props) {
  let { log_doc: doc, log_doc_status, data, setData, readOnly, trackerId, commitId, modalTitle, templateUri } = props;

  if (!doc) {
    return null;
  }

  // Try to get the template from document meta_json - fallback to hard-coded templateUri (prop)
  templateUri = doc.getIn(["meta_json", "input_details", "file_template", "url"]) || templateUri;

  let path = doc?.get("_path");

  //let modalId = LOG_MODAL_ID;
  let modalId = `${doc?.get("uid")}-global-modal`;
  // let modalTitle = "Employee input form";
  let currentDocObject = log_doc_status?.get("current_object");

  let formDetails = doc?.getInPath("meta_json.input_details") || Traec.Im.Map();
  let formFields = formDetails.get("fields");

  console.log("Rendering EmployeeLogModal for document", doc?.get("uid"), "at path ", path, "modalId", modalId, templateUri);

  return (
    <WizardModal
      hideClose={false}
      id={modalId}
      fullWidth={true}
      body={
        <div className="container-lg shadow rounded m-3">
          <h4 className="p-3">{modalTitle}</h4>
          <DataTable
            doc={doc}
            currentDocObject={currentDocObject}
            readOnly={readOnly}
            fields={formFields}
            formName={modalTitle}
            data={data}
            setData={setData}
            trackerId={trackerId}
            commitId={commitId}
            path={path}
            templateUri={templateUri}
          />
        </div>
      }
    />
  );
}

function ReportMetaModal(props) {
  let { commit, modalTitle, btnText, revalidate, disableInputs, logNodes, logStatuses } = props;

  if (!commit) {
    return null;
  }
  let modalId = "ReportMetaDataModal";

  // let [shown, setShown] = useState(isMetaComplete(commit?.get("meta_json")));
  let [employeeData, setEmployeeData] = useState(Traec.Im.List());
  let [voluntaryLeaversData, setVoluntaryLeaversData] = useState(Traec.Im.List());

  // useEffect(() => {
  //   // if (!shown) {
  //   //$(`#${modalId}`).modal("show");
  //   // $(`#${modalId}`).modal({ backdrop: "static", keyboard: false });
  //   // setShown(true);
  //   // }
  // });

  let metaComplete = isMetaComplete(commit?.get("meta_json"));
  let logsRequired = requireLogs(commit);
  let logsComplete = logStatuses.every((i) => !!i?.getInPath("current_object.url"));
  console.log("Checking log statuses", logsComplete, logStatuses?.toJS());

  const stepComplete = (step) => {
    console.log("stepComplete");
    return logStatuses?.get(step)?.getInPath("current_object.url");
  };

  return (
    <ErrorBoundary>
      <EmployeeLogModal
        {...props}
        data={employeeData}
        setData={setEmployeeData}
        log_doc={logNodes.get(0)}
        log_doc_status={logStatuses.get(0)}
        modalTitle={"Employee input form"}
        templateUri={"/assets/files/Current_Employee_log_2024.xlsx"}
      />
      <EmployeeLogModal
        {...props}
        data={voluntaryLeaversData}
        setData={setVoluntaryLeaversData}
        log_doc={logNodes.get(1)}
        log_doc_status={logStatuses.get(1)}
        modalTitle={"Voluntary leavers form"}
        templateUri={"/assets/files/Voluntary_leavers_log_2024.xlsx"}
      />
      <WizardModal
        hideClose={false}
        id={modalId}
        title={modalTitle || "Additional meta-data"}
        body={
          <ErrorBoundary>
            <div className="container shadow rounded m-3">
              <h3 className="p-3">Company information</h3>
              <p>
                Please complete your company information below. Once in the survey, you can edit this data by clicking
                on the "Company Information" button at the top of the report.
              </p>
              <hr />
              <SetMetaDataFields
                disabled={disableInputs}
                hideAdmin={true}
                //hideSave={true}
                //saveOnBlur={true}
                saveMetaFetchProps={{
                  handler: "tracker_ref_commit",
                  method: "patch",
                  params: {
                    trackerId: commit.get("tracker"),
                    refId: commit.get("ref"),
                    commitId: commit.get("uid"),
                  },
                  successHook: (data) => {
                    if (revalidate) {
                      revalidate();
                    }
                    let _meta = Traec.Im.fromJS(data || {})?.get("meta_json");
                    console.log("Validating saved meta-data", _meta?.toJS());
                    if (isMetaComplete(_meta)) {
                      console.log("Meta data is complete, calling handleMetaComplete");
                      handleMetaComplete(_meta, modalId, { ...props });
                    } else {
                      console.log("Meta data is not complete. Popping up modal alert.");
                      alertSuccess({
                        title: "Company information not complete",
                        text: "You must complete all fields in the company information before you can submit this report",
                        iconType: "error",
                      });
                    }
                  },
                }}
                metaJson={commit.get("meta_json")}
              />
              <br />
              <br />
              <br />
            </div>
          </ErrorBoundary>
        }
      />
      <div className="container shadow rounded my-3">
        <div className="row p-2 m-2 justify-content-between">
          <div className="row">
            <h5 className="h5">Step 1/4</h5>
            <span className={`text-muted align-text-bottom mt-1 ml-2 `}>
              <span className="mr-2">
                <Tooltip html={metaComplete ? "Complete" : "Not complete"} animateFill={false}>
                  <Octicon
                    name={metaComplete ? "verified" : "unverified"}
                    className={`${metaComplete ? "text-success" : "text-warning"}`}
                  />
                </Tooltip>
              </span>
              required
            </span>
          </div>
          <BSBtn
            text={btnText || "Edit meta-data"}
            dataBackdrop={"static"}
            dataKeyboard={false}
            //onClick={() => $(`#${modalId}`).modal("show")}
            onClick={() => $(`#${modalId}`).modal({ backdrop: "static", keyboard: false })}
          />
        </div>
      </div>
      <div className="container shadow rounded my-3">
        <div className="row p-2 m-2 justify-content-between">
          <div className="row">
            <h5 className="h5">Step 2/4</h5>
            <span className="text-muted align-text-bottom mt-1 ml-2">
              <span className="mr-2">
                <Tooltip html={!!stepComplete(0) ? "Complete" : "Not complete"} animateFill={false}>
                  <Octicon
                    name={!!stepComplete(0) ? "verified" : "unverified"}
                    className={!!stepComplete(0) ? "text-success" : "text-warning"}
                  />
                </Tooltip>
              </span>
              {logsRequired ? "required" : "recommended"}
            </span>
          </div>
          <BSBtn
            text={"Current employee log"}
            dataBackdrop={"static"}
            dataKeyboard={false}
            //onClick={() => $(`#${modalId}`).modal("show")}
            onClick={() =>
              loadEmployeeLogForm({
                ...props,
                setData: setEmployeeData,
                log_doc: logNodes.get(0),
                log_doc_status: logStatuses.get(0),
              })
            }
          />
        </div>
      </div>
      <div className="container shadow rounded my-3">
        <div className="row p-2 m-2 d-flex align-items-center justify-content-between">
          <div className="row">
            <h5 className="h5">Step 3/4</h5>
            <span className="text-muted align-text-bottom mt-1 ml-2">
              <span className="mr-2">
                <Tooltip html={!!stepComplete(1) ? "Complete" : "Not complete"} animateFill={false}>
                  <Octicon
                    name={!!stepComplete(1) ? "verified" : "unverified"}
                    className={!!stepComplete(1) ? "text-success" : "text-warning"}
                  />
                </Tooltip>
              </span>
              {logsRequired ? "required" : "recommended"}
            </span>
          </div>
          <BSBtn
            text={"Voluntary leavers log"}
            dataBackdrop={"static"}
            dataKeyboard={false}
            //onClick={() => $(`#${modalId}`).modal("show")}
            onClick={() =>
              loadEmployeeLogForm({
                ...props,
                setData: setVoluntaryLeaversData,
                log_doc: logNodes.get(1),
                log_doc_status: logStatuses.get(1),
              })
            }
          />
        </div>
      </div>
      <div style={{ clear: "both" }} />
      <VerticalTabbedReport {...props} disableButton={logsRequired && !logsComplete} />
    </ErrorBoundary>
  );
}

export function ProjectNoReport({ companyId, location }) {
  let [confirmed, setConfirmed] = useState(false);
  let { state } = location;
  let next = state?.next || "/user/profile/";
  let text = state?.next ? "Go to my company dashboard" : "Go to my profile";
  return confirmed ? (
    <Redirect to={next} />
  ) : (
    <BSCard
      widthOffset={"mt-3"}
      title={"Reporting Complete"}
      body={
        <React.Fragment>
          <p>Your report has already been submitted for this year. You will be redirected to your company dashboard.</p>
          <button className="btn btn-success" onClick={() => setConfirmed(true)}>
            {text}
          </button>
        </React.Fragment>
      }
    />
  );
}

class ProjectReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchedEdges: false,
      fetchedRootCommitBranches: false,
      fetchedConversionFactors: false,
      fetchedValues: false,
      fetchedUrls: {},
      showDocs: false,
      nameFormParams: {
        stateParams: {},
        fetchParams: {},
        initFields: {},
      },
      commitFormParams: {
        stateParams: {},
        fetchParams: {},
        initFields: {},
      },
      isLoading: true,
      disableInputs: false,
      inputErrors: Traec.Im.Map(),
      sectionsComplete: Traec.Im.Map(),
      madeConversionMap: false,
      doneInitialValidation: false,
      convFactorDetails: {},
    };

    this.requiredFetches = [
      new Traec.Fetch("project_reporting_periods", "list"),
      new Traec.Fetch("project_permission", "list"),
      new Traec.Fetch("project_discipline", "list"),
      new Traec.Fetch("tracker_ref_commit", "list"),
      new Traec.Fetch("tracker_node", "list"),
      new Traec.Fetch("tracker_ref", "read"),
      new Traec.Fetch("project_tracker", "list"),
      new Traec.Fetch("tracker_commit_value", "list"),
      new Traec.Fetch("tracker_commit_branch", "list"),
      new Traec.Fetch("tracker_commit_convfactor", "list"),
      new Traec.Fetch("tracker_branch", "list"),
      new Traec.Fetch("company", "read"),
      new Traec.Fetch("company", "list"),
      new Traec.Fetch("project", "read"),
      new Traec.Fetch("project", "list"),
    ];

    // action bindings
    this.updateError = this.updateError.bind(this);
    this.needsRevision = this.needsRevision.bind(this);
    this.updateSectionComplete = this.updateSectionComplete.bind(this);
    this.setSectionComplete = this.setSectionComplete.bind(this);
    this.doInitialValidation = this.doInitialValidation.bind(this);
    this.validateSectionComplete = this.validateSectionComplete.bind(this);
  }

  /**********************
     COMPONENT METHODS
     **********************/

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  componentDidUpdate(prevProps) {
    Traec.fetchRequiredFor(this);
    let {
      isResponsible,
      hasConversionMap,
      commitNodes,
      hasScoreValues,
      commitId,
      categoryTrees,
      conversionFactors,
      baseMetrics,
    } = this.props;
    let { disableInputs, madeConversionMap, doneInitialValidation, convFactorDetails } = this.state;

    this.setIsLoading();

    if (conversionFactors && commitId && convFactorDetails && !convFactorDetails.convFactorMap) {
      // Load the conversion factors into state
      let state = Traec.Im.Map()
        .setInPath(`entities.commitEdges.byId.${commitId}.conversionFactors`, conversionFactors)
        .setInPath(`entities.baseMetrics.byId`, baseMetrics);
      // Set conversion factor map in state to avoid re-calculating in mapStateToProps
      this.setState({
        convFactorDetails: {
          convFactRef: null,
          convFactorMap: loadConvFacts(state, commitId),
        },
      });
    }

    // Construct the initial conversion factor maps
    if (
      !madeConversionMap &&
      hasScoreValues &&
      commitNodes &&
      convFactorDetails &&
      convFactorDetails.convFactorMap &&
      !hasConversionMap
    ) {
      setInitialConversions(commitId, commitNodes, convFactorDetails);
      this.setState({
        madeConversionMap: true,
      });
    }

    // Do an initial validation of the form after loading the scoreValues
    if (!doneInitialValidation && categoryTrees && hasScoreValues && commitNodes) {
      this.doInitialValidation();
    }

    // If we are responsible and the form is hidden then turn it on
    let shouldHide = false;
    if (!isResponsible) {
      shouldHide = true;
    }
    // If we are on a past-commit then hide the inputs (unless we are revising it)
    if (this.isOnHold() || (!this.needsRevision() && this.props.match.params._commitId)) {
      shouldHide = true;
    }

    // Set the state based on the outcome
    if (disableInputs !== shouldHide) {
      this.setState({ disableInputs: shouldHide });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      hasDiff(this.props, nextProps, "ProjectReport props") || hasDiff(this.state, nextState, "ProjectReport state")
    );
  }

  setIsLoading() {
    let { categoryTrees } = this.props;
    if (!categoryTrees || categoryTrees.length === 0) {
      return null;
    }
    if (categoryTrees && this.state.isLoading) {
      this.setState({ isLoading: false });
    }
  }

  isOnHold() {
    let { commit } = this.props;
    let commitStatus = getCommitStatus(commit);
    return commitStatus && commitStatus.startsWith("Not for");
  }

  needsRevision() {
    let { tracker, cref, isResponsible, commit } = this.props;
    let needsRevision = false;
    if (tracker && cref && commit) {
      needsRevision = isResponsible && commit.getInPath("meta_json.status") === "pending_revision";
    }
    return needsRevision;
  }

  /**********************
     MENU METHODS
     **********************/

  getReportLayout() {
    let { commit } = this.props;
    return (commit ? commit.getInPath("meta_json.report_layout") : "classic") || "classic";
  }

  doInitialValidation() {
    let { categoryTrees, dispatch, commit, revalidateCount } = this.props;
    let { disableInputs } = this.state;
    console.log("Doing initial validation of loaded data for form", disableInputs);

    let sectionsComplete = Traec.Im.Map();
    let mustHaveInputs = this.getReportLayout() != "classic";

    for (let tree of categoryTrees.filter((i) => i)) {
      let categoryPath = tree.get("_path");
      // If we have disabled inputs then mark all sections as complete
      console.log("Doing initial validation for category", tree.get("name"), categoryPath);
      if (disableInputs) {
        sectionsComplete = sectionsComplete.set(categoryPath, true);
      } else {
        //sectionsComplete = this.updateSectionComplete(categoryPath, sectionsComplete, mustHaveInputs);
        let _key = `complete_${categoryPath}`;
        sectionsComplete = sectionsComplete.set(categoryPath, (localStorage.getItem(_key) === "true"))
      }
    }

    console.log("Done initial validation. Setting section complete", sectionsComplete.toJS());
    this.setState({
      doneInitialValidation: true,
      sectionsComplete,
    });

    console.log("DISPATCHING REVALIDATE COUNT", revalidateCount);
    dispatch({
      type: "UI_SET_IN",
      payload: { revalidateCount: revalidateCount + 1 },
      stateParams: {
        itemPath: `reports.${commit.get("uid")}`,
      },
    });
  }

  updateSectionComplete(categoryPath, sectionsComplete = null, mustHaveInputs = false) {
    console.log("Determining if section has all required fields complete", categoryPath);
    let { commitNodes, metricScores, baseMetrics, currentReportingPeriod, scoreValues } = this.props;
    sectionsComplete = sectionsComplete || this.state.sectionsComplete;

    if (localStorage.getItem(`complete_${categoryPath}`) == "true") {
      console.log("Got section complete status from localStorage as true");
      return sectionsComplete.set(categoryPath, true);
    }

    // Always have a valid Map to avoid errors
    scoreValues = scoreValues || Traec.Im.Map();

    // Get the metricscores and baseMetricIds that are under this category/issue
    let categoryMetricScores = getChildMetricScores(categoryPath, commitNodes, metricScores);
    let baseMetricIds = Traec.Im.Set(categoryMetricScores.map((i) => i.getInPath("metric.uid") || i.get("metric")));

    // Get the input score values by BaseMetricId
    let categoryScoreValues = getScoreValuesByBaseMetric(scoreValues, baseMetricIds);

    let complete = false;
    if (mustHaveInputs && !categoryScoreValues.size) {
      complete = false;
    } else {
      // If we have inputs then check all required is submitted
      complete = isAllRequiredSubmitted(categoryScoreValues, categoryMetricScores, baseMetrics, currentReportingPeriod);
    }

    // Log status
    console.log(
      "Setting cateogory at path complete status",
      categoryPath,
      Traec.Im.isImmutable(complete) ? complete.toJS() : complete,
    );
    return sectionsComplete.set(categoryPath, complete);
  }

  validateSectionComplete(categoryPath) {
    let { inputErrors } = this.state;
    let status = false;

    let errors = inputErrors.get(categoryPath);
    if (errors && errors.size) {
      // First check there are no outstanding input errors
      console.warn("Section has errors - cannot mark as complete", categoryPath, errors.size, errors.toJS());
      let error_lines = errors
        .map((value, key, i) => `${key}`)
        .toList()
        .join("\n");
      alert(`Please correct input errors:\n${error_lines}`);
      status = errors;
    } else {
      // Check that all required fields are complete
      status = Traec.Im.fromJS(this.updateSectionComplete(categoryPath).get(categoryPath));
      if (!(status === true)) {
        console.warn("Section has required fields to complete - cannot mark as complete", categoryPath, status.toJS());
        alert(`Please complete the mandatory fields:\n${status.join("\n")}`);
      }
    }
    return status;
  }

  setSectionComplete(categoryPath, value) {
    console.log("Toggling section complete", categoryPath, value);
    let { sectionsComplete, inputErrors } = this.state;

    // If setting section as complete then do some validation first
    if (value === true) {
      value = this.validateSectionComplete(categoryPath);
    }

    let _sectionsComplete = sectionsComplete.set(categoryPath, value);
    console.log("Setting section complete", _sectionsComplete?.toJS());
    this.setState({ sectionsComplete: _sectionsComplete });

    // Set the localStorage
    let _key = `complete_${categoryPath}`;
    console.log(`Setting local storage key: ${_key}`, value);
    localStorage.setItem(_key, value);
  }

  updateError(metricName, value, isRequiredInPeriod, categoryPath) {
    let { inputErrors } = this.state;
    let _categoryErrors = Traec.Im.Map();

    categoryPath = categoryPath || "";
    let categoryErrors = inputErrors.get(categoryPath) || Traec.Im.Map();

    if (typeof value === "number" || value == null) {
      _categoryErrors = categoryErrors.delete(metricName);
    } else {
      _categoryErrors = categoryErrors.set(metricName, Traec.Im.Map({ name: metricName, value: value }));
    }

    let _inputErrors = inputErrors.set(categoryPath, _categoryErrors);

    console.log("Updating input errors for category", categoryPath);
    if (this.getReportLayout() == "classic") {
      this.setState({
        inputErrors: _inputErrors,
        //sectionsComplete: this.updateSectionComplete(categoryPath),
      });
    } else {
      this.setState({
        inputErrors: _inputErrors,
      });
    }
  }

  render() {
    const { commit, company, project, cref, isRootRef, currentReportingPeriod } = this.props;
    let { isLoading, inputErrors, sectionsComplete, disableInputs, convFactorDetails } = this.state;
    console.log("CALLING render on ProjectReport", isLoading);

    //console.log("RENDERING PROJECT REPRORT WITH sectionsComplete", sectionsComplete.toJS());

    // if (!isRootRef && !isLoading && commit && project && cref && !commit.get("reporting_period")) {
    //   let projectId = project.get("uid")?.substring(0, 8);
    //   let refId = cref.get("uid")?.substring(0, 8);
    //   let companyId = project.getInPath("company.uid")?.substring(0, 8);
    //   return (
    //     <Redirect
    //       to={{
    //         pathname: `/company/${companyId}/`, // `/project/${projectId}/wpack/${refId}/noreport/`,
    //         state: { next: `/company/${companyId}/` }
    //       }}
    //     />
    //   );
    // }

    if (isLoading) {
      return (
        <Spinner
          title="Loading..."
          explanation={"Loading report..."}
          timedOutComment={"Still loading, please be patient..."}
        />
      );
    }

    return (
      <ErrorBoundary>
        <div className="container p-0">
          <div
            className="jumbotron jumbotron-fluid text-white rounded text-center mb-0 pb-4"
            style={{ backgroundColor: "#337ab7" }}
          >
            <h3 className="display-5 font-weight-bold text-white">
              <b>
                Annual Diversity Survey
                {/* <ReportPeriodYear reportingPeriod={currentReportingPeriod} /> */}
              </b>
            </h3>
            <ul style={{ listStyle: "none" }}>
              <li className="mt-3 text-white">
                <p className="text-white">
                  Please complete the steps then click <b>Submit survey</b> below
                </p>
              </li>
            </ul>
          </div>
        </div>

        {/* Render the loading spinning wheel */}
        {isLoading ? (
          <TipsSpinner message="Loading report" />
        ) : (
          <ReportBody
            {...this.props}
            inputErrors={inputErrors}
            needsRevision={this.needsRevision}
            updateError={this.updateError}
            disableInputs={disableInputs}
            sectionsComplete={sectionsComplete}
            setSectionComplete={this.setSectionComplete}
            convFactorDetails={convFactorDetails}
            revalidate={this.doInitialValidation}
          />
        )}
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  //let { _projectId, _refId, _commitId } = ownProps.match.params;
  let { projectId, refId, commitId } = Traec.utils.getFullIds(state, ownProps.match.params);

  let { company, project, tracker, trackerId, cref, crefId, rootRef, isRootRef } = getProjectProps(
    state,
    projectId,
    refId,
  );

  // Get the commit data from the ref (or from flattened commitId)
  let commit = cref ? cref.get("latest_commit") : null;
  if (commitId && state.getInPath(`entities.commits`)) {
    commit = state.getInPath(`entities.commits.byId.${commitId}`);
  }

  // Get the root tree to start with
  let rootTree = commit ? commit.get("tree_root") : null;
  let rootTreeId = rootTree ? rootTree.get("uid") : null;

  // Get all of the trees that are children of the rootTree (edges)
  commitId = commit ? commit.get("uid") : null;
  // Get all of the trees that are children of the rootTree (edges)
  let commitNodes = commitId ? state.getInPath(`entities.commitNodes.${commitId}`) : null;

  // To force the component to reload when the re-validate button is pressed
  let revalidateCount = state.getInPath(`ui.reports.${commitId}.revalidateCount`) || 0;
  //console.log("EXECUTING mapStateToProps FOR ProjectReport", revalidateCount);

  // Get the root children
  let pathRoot = null;
  let categoryTrees = null;
  if (commitNodes) {
    pathRoot = commitNodes.get("pathRoot");
    categoryTrees = getPathChildren(state, pathRoot, commitNodes, "trees").filter((i) => i);
  }

  // Load existing values input for this commit
  let scoreValues = commitId ? state.getInPath(`entities.commitEdges.byId.${commitId}.bmScoreValues`) : null;
  let hasScoreValues = state.getInPath(`entities.commitEdges.byId.${commitId}.bmScoreValues`) != null;

  // Get metric scores from Redux (filter down to only the metric scores used in this Report)
  let metricScores = state.getInPath("entities.metricScores.byId") || Traec.Im.Map();
  let reportMetricScoreIds = Traec.Im.Set(
    ((commitNodes || Traec.Im.Map()).get("byPath") || Traec.Im.Map())
      .filter((i) => i.get("type") == "metricscore")
      .toList()
      .map((i) => i.get("uid")),
  );
  metricScores = metricScores.filter((value, id) => reportMetricScoreIds.has(id));

  // Get BaseMetrics
  let conversionFactors = state.getInPath(`entities.commitEdges.byId.${commitId}.conversionFactors`);
  let baseMetrics = state.getInPath("entities.baseMetrics.byId");

  // Get inputs for each Category
  let categoryPathMap = (categoryTrees || Traec.Im.List()).reduce(
    (acc, cur) => acc.set(cur.get("_path"), cur),
    Traec.Im.Map(),
  );
  let inputsByCategoryPath = categoryPathMap.map((tree, categoryPath) => {
    let categoryMetricScores = getChildMetricScores(categoryPath, commitNodes, metricScores);
    return categoryMetricScores
      .map((ms) => state.getInPath(`entities.commitEdges.byId.${commitId}.scoreValues.${ms.get("uid")}`))
      .filter((i) => i);
  });

  // Get the project reporting periods
  let projectReportingPeriods = crefId
    ? state.getInPath(`entities.projectReportingPeriods.ref.${crefId}.byId.${projectId}`)
    : null;
  if (!projectReportingPeriods) {
    projectReportingPeriods = state.getInPath(`entities.projectReportingPeriods.byId.${projectId}`);
  }

  let currentReportingPeriodId = commit ? commit.get("reporting_period") : null;
  let currentReportingPeriod = projectReportingPeriods ? projectReportingPeriods.get(currentReportingPeriodId) : null;

  // Get a map of Project Disciplines
  let projectDisciplines = state.getInPath(`entities.projectObjects.byId.${projectId}.disciplines`) || Traec.Im.List();
  let projectBaseDisciplineMap = projectDisciplines.reduce((obj, i) => obj.set(i.get("base_uid"), i), Traec.Im.Map());

  // ONLY ALLOW RESPONSIBLE PEOPLE OF THIS REPORT
  // Get the project permissions for this user
  let userProjectPermissions =
    state.getInPath(`entities.projectObjects.byId.${projectId}.userPermission`) || Traec.Im.Map();
  let isResponsible = userProjectPermissions.get("is_admin");

  if (commit && !isResponsible) {
    let responsibleDiscipline = commit.get("discipline");
    let userDisciplines = userProjectPermissions.get("baseDisciplineIds") || Traec.Im.List();
    isResponsible = userDisciplines.contains(responsibleDiscipline);
  }

  // Get the initial conversion map (to avoid commponents hitting the server on the first page load)
  let hasConversionMap = state.getInPath(`ui.childConversions.${commitId}`) != null;

  // sort alphabetically by default
  let sortKey = commit ? commit.getInPath("meta_json.sortKey") || "name" : "name";

  let logNodePaths = commitNodes
    ?.get("byPath")
    ?.filter((node) => node.get("type") == "document")
    ?.map((node, path) => node.set("_path", path))
    ?.toList();

  let logNodes = logNodePaths
    ?.map((nodePath) =>
      state.getInPath(`entities.documents.byId.${nodePath.get("uid")}`)?.set("_path", nodePath.get("_path")),
    )
    ?.sortBy((i) => (i?.get("name") || "").includes("mployee"))
    .reverse();
  console.log("Got employee log documents >>", logNodes?.toJS());

  // We will only have a status for a document (aka file upload) after a user has uploaded some information
  let logStatuses = logNodes?.map((node) =>
    getPathChildren(state, node?.get("_path"), commitNodes, "documentstatus").first(),
  );

  console.log("Got employee log document status objects", logStatuses?.toJS());

  // GET THE SPECIAL LOG DATA DOCUMENT AND CURRENT DOCUMENT ID
  // let log_doc_node = commitNodes
  //   ?.get("byPath")
  //   ?.filter(node => node.get("type") == "document")
  //   ?.map((node, path) => node.set("_path", path))
  //   ?.toList()
  //   ?.first();
  // let log_doc_path = log_doc_node?.get("_path");
  // let log_doc_id = log_doc_node?.get("uid");
  // let log_doc = state.getInPath(`entities.documents.byId.${log_doc_id}`)?.set("_path", log_doc_path);
  // if (log_doc) {
  //   console.log(`Got employee log document `, log_doc_id, " at path ", log_doc_path);
  // }

  /*
  let log_doc = state.getInPath(`entities.documents.byId.${LOG_DOCUMENT_ID}`);
  let log_doc_path = commitNodes
    ?.get("byPath")
    ?.filter(node => node.get("uid") == LOG_DOCUMENT_ID)
    ?.keySeq()
    ?.toList()
    ?.first();
  if (log_doc && log_doc_path) {
    log_doc = log_doc?.set("_path", log_doc_path);
  }
  */

  // let log_doc_status = state
  //   .getInPath("entities.documentstatus.byId")
  //   ?.toList()
  //   ?.filter(
  //     i => i.getInPath("current_object.document") == log_doc_id && i.getInPath("current_object.commit") == commitId
  //   )
  //   ?.first();
  // if (log_doc_status) {
  //   console.log("Got employee log document status objects", log_doc?.toJS(), log_doc_status?.toJS());
  // }

  // Add this to props
  return {
    projectId,
    project,
    company,
    trackerId,
    tracker,
    cref,
    refId: crefId,
    commit,
    commitId,
    isRootRef,
    rootTree,
    rootTreeId,
    rootTreePath: pathRoot,
    commitNodes,
    categoryTrees,
    scoreValues,
    hasScoreValues,
    metricScores,
    baseMetrics,
    projectReportingPeriods,
    currentReportingPeriod,
    userProjectPermissions,
    isResponsible,
    trackerRootRef: rootRef,
    projectDisciplines,
    projectBaseDisciplineMap,
    conversionFactors,
    hasConversionMap,
    inputsByCategoryPath,
    revalidateCount,
    sortKey,
    logNodes,
    logStatuses,
  };
};

export default connect(mapStateToProps)(ProjectReport);
